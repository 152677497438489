<template>
    <div id="os_pf_identity_verification" class="vx-col sm:w-5/6 md:w-2/3 lg:w-2/3 xl:w-2/5 xxl:w-2/4 m-0">
        <div class="vx-row no-gutter items-center justify-center p-6">
            <vx-card class="login-card py-3 px-3">
                <div slot="no-body" class="">
                    <vs-progress :percent="95" :height="10" color="primary"></vs-progress>
                    <div class="vx-col sm:w-full">
                        <div class="login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full p-0" style="min-height: 200px">
                                    <!-- MAIN-DIV -->
                                    <div class="p-10">
                                        <!-- HEADER -->
                                        <div class="vx-row mb-2">
                                            <div class="vx-col w-full align-center">
                                                <p class="text-4xl card-sub-title">Verificación de identidad.</p>
                                            </div>
                                        </div>
                                        <!-- CONTENT -->
                                        <div class="vx-row">
                                            <div class="vx-col w-full mb-base card-img">
                                                <img :src="identityCardURL" alt="Verificación de identidad RG">
                                            </div>
                                            <div class="vx-col w-full">
                                                <div class="vx-row justify-between">
                                                    <div class="text-sm vx-col w-full mb-4">
                                                        <p>Comprobemos que eres tú. Te compartimos algunas sugerencias para la verificación:</p>
                                                    </div>
                                                    <div class="text-sm vx-col sm:w-full md:w-1/2 mb-4">
                                                        <ul class="ul-disc ml-4">
                                                            <li>Ingresa desde un dispositivo con cámara</li>
                                                            <li>Hazlo en un lugar iluminado</li>
                                                            <li>Ten a la mano tu identificación oficial</li>
                                                        </ul>
                                                    </div>
                                                    <div class="align-center md:w-1/2 mt-2 sm:w-full vx-col">
                                                        <vs-button @click.stop="openMatiPopup" color="black">Comenzar</vs-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
        <!-- MATI -->
        <mati-popup 
            :user-id-to-activate="loan.applicant.uid" 
            :personal-profile-id="personal.id"
            :citizenship="personal.citizenship"
            @on-mati-process-started="onMatiProcessStarted" 
            @finished="onMatiProcessFinished"
            />
    </div>
</template>

<script>
import MatiPopup from "@components/mati/MatiPopup";
export default {
    name: "GuaranteeOnboardingIdentityVerification",
    props: ["guarantee", "isRepLegal"],
    components: {
        MatiPopup,
    },
    data() {
        return {
            
        }
    },
    async mounted() {
    },
    computed: {
        personal() {
            if(this.isRepLegal) {
                return this.guarantee.business.personal;
            }
            return this.guarantee.personal;
        },
        loan() {
            return this.guarantee.loan;
        },
        address() {
            return this.personal.address
        },
        country() {
            return this.address.country
        },
        identityVerification() {
            return this.personal.identity_verifications.length > 0 ? this.personal.identity_verifications[0] : null;
        },
        identityCardURL() {
            return `${this.StorageURL}/applicants/guarantees-onboarding/identity-card-icon.png`;
        }
    },
    methods: {
        async onMatiProcessStarted() {
            this.$store.commit("SET_MATI_PROCESS_IS_RUNNING", true);
            this.$store.commit("TOGGLE_IS_MATI_POPUP_ACTIVE", false);
        },
        async openMatiPopup() {
            this.$store.commit("SET_MATI_PROCESS_IS_RUNNING", true);
            this.$store.commit("TOGGLE_IS_MATI_POPUP_ACTIVE", true);
        },
        async onMatiProcessFinished() {
            try {
                await this.$emit('updated');
            } catch (error) {
                this.failedOperationNotif();
                console.log(error);
            }
        }
    }
}
</script>

<style scoped>
.card-img {
    text-align: center;
    text-align: -webkit-center;
}
    .card-img img {
        width: 100%;
        max-width: 350px;
    }

</style>