<template>
    <div id="os_pf_ap_tc_acceptance" :class="isEditing ? 'vx-col w-full' : 'vx-col sm:w-5/6 md:w-2/3 lg:w-2/5 xl:w-2/5 xxl:w-2/5 m-0'">
        <div class="vx-row no-gutter items-center justify-center p-6">
            <vx-card class="login-card py-3 px-3">
                <div slot="no-body" class="">
                    <vs-progress v-if="!isEditing" :percent="15" :height="10" color="primary"></vs-progress>
                    <div class="vx-col sm:w-full">
                        <div class="login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full p-0" style="min-height: 200px">
                                    <!-- MAIN-DIV -->
                                    <div class="p-10">
                                        <!-- HEADER -->
                                        <div class="vx-row mb-4 center">
                                            <div class="vx-col w-full">
                                                <p class="text-4xl card-sub-title">Información personal.</p>
                                            </div>
                                        </div>
                                        <!-- HEADER -->
                                         <!-- MAIN CONTENT -->
                                        <div v-if="isMounted" class="main-form mt-base">
                                            <!-- DATA -->
                                            <div class="vx-row mb-4">
                                                <div class="vx-col w-full mb-4">
                                                    <p>
                                                        <span class="bold">
                                                            Nombre completo <br>
                                                        </span>
                                                        {{ `${personal.first_name || ""} ${personal.second_name || ""} ${personal.last_name_1 || ""} ${personal.last_name_2 || ""}` }}
                                                    </p>
                                                </div>
                                                <div class="vx-col sm:w-full md:w-1/2 mb-4">
                                                    <p>
                                                        <span class="bold">
                                                            Correo <br>
                                                        </span>
                                                        {{ guarantee.email }}
                                                    </p>
                                                </div>
                                                <div class="vx-col sm:w-full md:w-1/2 mb-4">
                                                    <p>
                                                        <span class="bold">
                                                            Teléfono <br>
                                                        </span>
                                                        ({{ personal.country_calling_code }}) {{ phoneFormat(personal.new_phone) }}
                                                    </p>
                                                </div>
                                                <div class="vx-col sm:w-full  mb-4">
                                                    <p class="text-sm">
                                                        Si alguno de estos datos no es correcto por favor contacta a {{ loan.applicant.name }} para que se ponga en comunicación con nosotros vía chat y podamos revisar el caso.
                                                    </p>
                                                </div>
                                            </div>
                                            <!--  -->
                                            <!-- FORMS -->
                                            <div class="vx-row mb-base">
                                                <div :class="colClass">
                                                    <vs-select label="Género" name="gender" v-validate="requiredRules"
                                                        :danger="hasError('gender')"
                                                        :danger-text="errorText('gender')"
                                                        :success="isSuccess('gender')"
                                                        placeholder="Selecciona una opción"
                                                        v-model.lazy="currentGender"
                                                        class="mr-2 w-full">
                                                        <vs-select-item v-for="(item, index) in genderOptions"
                                                            :text="item.label" :key="index"
                                                            :value="item.value"></vs-select-item>
                                                    </vs-select>
                                                    <small><i>Selecciona el género que está en tu acta de nacimiento.</i></small>
                                                </div>
                                                <div :class="colClass">
                                                    <label class="vs-input--label">Fecha de nacimiento</label>
                                                    <vs-input
                                                        class="w-full"
                                                        type="date" 
                                                        v-model.lazy="currentBirthDate"
                                                        v-validate="requiredRules"
                                                        name="birthDate"
                                                        :danger="hasError('birthDate')"
                                                        :danger-text="errorText('birthDate')"
                                                        :success="isSuccess('birthDate')"
                                                        format="dd/MM/yyyy"
                                                        :max="disabledDates.from"/>
                                                </div>
                                                <div :class="colClass">
                                                    <label class="vs-input--label">Estado de nacimiento</label>
                                                    <v-select
                                                        class="vs-custom"
                                                        name="currentBirthState"
                                                        :class="{
                                                            'has-error': hasError('currentBirthState'),
                                                            'is-success': isSuccess('currentBirthState'),
                                                        }"
                                                        v-model.lazy="currentBirthState"
                                                        v-validate="requiredRules"
                                                        :options="collections.states"
                                                        :reduce="(item) => item.value"
                                                        :clearable="false"
                                                        placeholder="Selecciona una opción"
                                                        :searchable="false">
                                                    </v-select>
                                                    <v-error v-if="hasError('currentBirthState')" :error="errorText('currentBirthState')" />
                                                </div>
                                            </div>
                                            <div v-if="currentBirthState == birthStateIdForeign" class="vx-row mb-base">
                                                <div :class="colClass">
                                                    <label class="vs-input--label">País de nacimiento</label>
                                                    <v-select
                                                        class="vs-custom"
                                                        name="currentOriginCountry"
                                                        :class="{
                                                            'has-error': hasError('currentOriginCountry'),
                                                            'is-success': isSuccess('currentOriginCountry'),
                                                        }"
                                                        v-model.lazy="currentOriginCountry"
                                                        v-validate="requiredRules"
                                                        :options="collections.countries"
                                                        :reduce="(item) => item.value"
                                                        :clearable="false"
                                                        placeholder="Selecciona una opción"
                                                        :searchable="false">
                                                    </v-select>
                                                    <v-error v-if="hasError('currentOriginCountry')" :error="errorText('currentOriginCountry')" />
                                                </div>
                                                <div :class="colClass">
                                                    <label class="vs-input--label">Nacionalidad</label>
                                                    <v-select
                                                        class="vs-custom"
                                                        name="currentCitizenship"
                                                        :class="{
                                                            'has-error': hasError('currentCitizenship'),
                                                            'is-success': isSuccess('currentCitizenship'),
                                                        }"
                                                        v-model.lazy="currentCitizenship"
                                                        v-validate="requiredRules"
                                                        :options="collections.countries"
                                                        :reduce="(item) => item.value"
                                                        :clearable="false"
                                                        placeholder="Selecciona una opción"
                                                        :searchable="false">
                                                    </v-select>
                                                    <v-error v-if="hasError('currentCitizenship')" :error="errorText('currentCitizenship')" />
                                                </div>
                                            </div>

                                            <vs-alert
                                                v-if="errorMssg"
                                                icon-pack="feather"
                                                icon="icon-alert-triangle"
                                                class="mb-5"
                                                color="danger"
                                            >
                                                <span class="font-regular">{{ errorMssg }}</span>
                                            </vs-alert>

                                            <div class="vx-row">
                                                <div class="vx-col w-full">
                                                    <vs-button @click.stop="saveDataForm()" color="black" class="sm:w-1/2 md:w-1/4">Continuar</vs-button>
                                                </div>
                                            </div>
                                            <!-- FORMS -->
                                        </div>
                                        <!-- MAIN CONTENT -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import formatHelper from "@mixins/formatHelper";
import inputHelper from "@mixins/inputHelper";
import dateHelper from "@mixins/dateHelper";
import { es } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";

export default {
    name: "GuaranteeOnboardingGeneralData",
    props: ["guarantee", "isEditing"],
    mixins: [formatHelper, inputHelper, dateHelper],
    components: {
        Datepicker
    },
    data() {
        return {
            isMounted: false,
            requiredRules: "required",
            colClassFull: "vx-col w-full",
            colClass: "vx-col md:w-1/2 sm:w-full w-full mb-4",
            requiredPlaceholder: "(Requerido)",
            optionalPlaceholder: "(Opcional)",
            genderOptions: [
                {"value": 2, "label": "Mujer"},
                {"value": 1, "label": "Hombre"},
                // {"value": 0, "label": "No definido"},
            ],
            lang_es: es,
            mexicoId: 700,
            collections: {
                states: [],
                countries: [],
            },
            slas: {
                min_guarantee_age: null,
                max_guarantee_age: null,
            },
            disabledDates: {
                from: new Date(),
            },
            currentGender: null,
            currentBirthDate: null,
            currentBirthState: null,
            currentOriginCountry: null,
            currentCitizenship: null,
            birthStateIdForeign:null,
            errorMssg: null,
        }
    },
    async mounted() {
        this.showLoading(true);
        this.isMounted = false;
        await this.getCollections();
        this.setData();
        this.isMounted = true;
        this.showLoading(false);
    },
    computed: {
        personal() {
            return this.guarantee.personal;
        },
        loan() {
            return this.guarantee.loan;
        },
        guaranteeId() {
            return this.guarantee.id;
        }
    },
    methods: {
        async getCollections(){
            try {
                let collectionsObjects = ['countriesList', 'statesList'];
                let params = "with[]=" + collectionsObjects.join("&with[]=");
                let res = await this.publicApiGet(`/api/v1/forms/getPublicFormCollections?${params}`);
                let colls = res.data;
                colls.statesList.forEach(opt => {
                if (opt.name == "EXTRANJERO (FOREIGN)") {
                    this.birthStateIdForeign = parseInt(opt.id);
                }
                this.collections.states.push({value: parseInt(opt.id), label: opt.name}); //conversion a sintaxis de vx-select
                })
                colls.countriesList.forEach(opt => {
                    if (opt.id != 700 && opt.id != 600 && opt.id != 999) 
                        this.collections.countries.push({value: parseInt(opt.id), label: opt.name}); //conversion a sintaxis de vx-select
                })

                let slasObjects = [
                        'min_guarantee_age',
                        'max_guarantee_age',  
                    ];

                let slasParams = "with[]=" + slasObjects.join("&with[]=");
                let slasRes = await this.publicApiGet(`/api/v1/forms/getSlasValues?${slasParams}`);
                this.slas = slasRes.data;
            }
                catch (e) {
            }
        },
        async saveDataForm() {
            const event = this.isEditing ? "editGeneralData" : "saveGeneralData";
            this.errorMssg = null;
            if (!(await this.validateForm())) {
                return;
            }

            if(this.isAfter(this.birthDate, this.disabledDates.from)) {
                this.errorMssg = "La fecha de nacimiento no puede ser mayor a " + this.disabledDates.from;
                return;
            }

            if(!this.validate_guarantee_profile_age()) {
                this.errorMssg = `El obligado solidario debe tener entre ${this.slas.min_guarantee_age} y ${this.slas.max_guarantee_age} años`;
                return;
            }

            this.showLoading(true, "Guardando información...");
            try {
                if (this.currentBirthState != this.birthStateIdForeign) {
                    this.currentOriginCountry = this.mexicoId;
                    this.currentCitizenship = this.mexicoId;
                }
                let payload = {
                    guarantee_id: this.guaranteeId,
                    gender: this.currentGender,
                    birth_state: this.currentBirthState,
                    birth_date: this.yearDateFormat(this.currentBirthDate),
                    origin_country: this.currentOriginCountry,
                    citizenship: this.currentCitizenship,
                };
                await this.publicApiPost(
                    "/api/guarantee/post/onboarding/personal-data",
                    payload
                );
                this.showLoading(false);
                this.$emit("updated", event);
            } catch (e) {
                this.showLoading(false);
                this.showError(e);
            }
        },
        validate_guarantee_profile_age() {
            if(this.currentBirthDate == null) {
                return false;
            }

            let years = this.calculateAgeFromDate(this.currentBirthDate);
            let min = parseInt(this.slas.min_guarantee_age);
            let max = parseInt(this.slas.max_guarantee_age);
            return years >= min && years <= max;
        },
        async validateForm() {
            this.errorMssg = null;
            return this.$validator.validateAll();
        },
        setData() {
            const personal = this.personal;
            this.currentGender = personal.gender;
            this.currentBirthDate = personal.birth_date;
            this.currentBirthState = personal.birth_state;
            this.currentCitizenship = personal.citizenship;
        },
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
            let ff = this.fields[val];
            return (ff && ff.required && ff.valid);
        },
    }
}
</script>

<style scoped>


</style>