<template>
    <div id="os_pm_sat" class="vx-col sm:w-full md:w-2/3 lg:w-1/2 xl:w-1/2 xxl:w-1/2 m-0">
        <div class="vx-row no-gutter items-center justify-center p-6">
            <vx-card class="login-card py-3 px-3">
                <div slot="no-body" class="">
                    <vs-progress :percent="45" :height="10" color="primary"></vs-progress>
                    <div class="vx-col sm:w-full">
                        <div class="login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full p-0" style="min-height: 200px">
                                    <!-- MAIN-DIV -->
                                    <div v-if="isMounted" class="px-6 pt-5 pb-5">
                                        <!-- HEADER -->
                                        <div class="vx-row mb-4 align-center">
                                            <div class="vx-col w-full">
                                                <p class="card-sub-title text-2x1">Vincula tu cuenta del SAT (CIEC)</p>
                                                <p class="text-sm">
                                                    Validaremos tu información de forma segura y confidencial. <br>
                                                    Tu información se mantiene privada.
                                                </p>
                                            </div>
                                        </div>
                                        <!-- CONTENT -->
                                        <div class="main-form syntage">
                                            <img :src="syntageLogo" alt="RG + SAT" class="fix-margin mb-4 w-8/10">
                                            <div class="vx-row mt-base">
                                                <div class="vx-col sm:w-full">
                                                    <p class="mt-2 mb-6 text-center black bold text-2xl">
                                                        <template v-if="!isEditingRFC">
                                                            RFC: {{ business.rfc }}
                                                        </template>
                                                        <template v-else>
                                                            <div class="flex mt-1 justify-center">
                                                                <vs-input 
                                                                    @input="(val) => (currentRfc = currentRfc.toUpperCase())"
                                                                    class="sm: w-full md:w-1/4"
                                                                    name="currentRfc"
                                                                    v-validate="requiredRules"
                                                                    v-model.lazy="currentRfc"
                                                                    :danger="hasError('currentRfc')"
                                                                    :danger-text="errorText('currentRfc')"
                                                                    :success="isSuccess('currentRfc')"
                                                                    icon-pack="feather"
                                                                    :placeholder="requiredPlaceholder"
                                                                />
                                                                <feather-icon @click.stop="saveRfc()" icon="SaveIcon" svgClasses="w-5 h-5 hover:text-success stroke-current cursor-pointer rfc" class="ml-2 pt-1" />
                                                            </div>
                                                        </template>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="vx-row content-center">
                                                <div :class="colClass" class="mb-base">
                                                    <label class="vs-input--label bold">Contraseña SAT (CIEC)</label>
                                                    <vx-input-group class="w-full">
                                                        <vs-input v-model.lazy="password"
                                                            v-validate="requiredRules" :danger="hasError('password')"
                                                            :danger-text="errorText('password')"
                                                            :type="passInputType"
                                                            name="password" />

                                                        <template slot="append">
                                                            <div class="append-text btn-addon" style="height:40px;">
                                                                <vs-button color="dark" @click="togglePassInputType()"
                                                                    icon-pack="feather"
                                                                    :icon="passInputIcon"></vs-button>
                                                            </div>
                                                        </template>
                                                    </vx-input-group>
                                                </div> 
                                                <!-- <div class="vx-col md:w-10/12 mb-base account-button mt-2">
                                                    <div class="flex">
                                                        <vs-checkbox v-model="legal"
                                                            name="legal" v-validate="requiredRules"
                                                            :danger="hasError('legal')"
                                                            :danger-text="errorText('legal')"
                                                            :success="isSuccess('legal')"></vs-checkbox>
                                                        <p class="text-sm text-justify">
                                                            <span class="bold">Autorización de consulta al Buró de Crédito</span> <br>
                                                            Estoy de acuerdo en permitir que RedGirasol (RED GIRASOL SAPI DE CV, INSTITUCION DE FINANCIAMIENTO COLECTIVO) acceda a mi historial en 
                                                            cualquier sociedad de información crediticia. Entiendo la información 
                                                            requerida, su propósito y otorgo permiso para consultas regulares. Esta 
                                                            autorización tendrá validez durante tres años o mientras mantengamos
                                                            nuestra relación. <br>
                                                        </p>
                                                    </div>
                                                </div>  -->
                                            </div>
                                        
                                            <vs-alert
                                                v-if="errorMssg"
                                                icon-pack="feather"
                                                icon="icon-alert-triangle"
                                                class="mb-5"
                                                color="danger"
                                            >
                                                <span class="font-regular">{{ errorMssg }}</span>
                                            </vs-alert>
                                            <div class="justify-center vx-row mt-2">
                                                <div class="vx-col md:w-1/2 sm:w-full w-full">
                                                    <vs-button :disabled="isDisable" id="4_stakeholders_confirm_btn" class="w-full" color="dark" @click="saveDataForm">Conectar cuenta</vs-button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="text-center text-base">
                                            <p @click.stop="showSyntageInfo = true" class="no-password">
                                                ¿Por qué te pedimos la cuenta del SAT (CIEC)?
                                            </p>
                                        </div>
                                        <!-- END CONTENT DIV -->
                                    </div>
                                    <!-- END MAIN DIV -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>

        <vs-popup
            title=""
            :active.sync="showSyntageInfo">
            <SyntageInfo />

        </vs-popup>
    </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import formatHelper from "@mixins/formatHelper";
import SyntageInfo from "@components/profile_shared/SyntageInfo.vue";

const textType = "text";
const iconEye = "icon-eye";
const iconEyeOff = "icon-eye-off";

export default {
    name: "GuaranteeOnboardingSyntagePage",
    props: ["guarantee"],
    mixins: [inputHelper, formatHelper],
    components: {
        SyntageInfo
    },
    data: () => ({
        requiredRules: 'required',
        colClass: "vx-col sm:w-full md:w-2/3 lg:w-2/3 mb-5 mt-2",
        passInputPassMode: true,
        legal: false,
        errorMssg: null,
        isMounted: false,
        syntageLogo: "https://cdn.redgirasol.com/applicants/syntage_schema.png",
        password: null,
        requiredPlaceholder: "(Requerido)",
        isEditingRFC: false,
        isEditingPhone: false,
        country_calling_code: null,
        currentPhone: null,
        currentRfc: null,
        nipIsCreated: false,
        nipRequested: null,
        applicantContractType: 4,
        applicantContractUrl: 'contrato-de-comision-mercantil-cliente',
        advertisingChecked: true,
        showWhatsapp: false,
        whatsappTime: 30,
        nipIsCreated: false,
        lastSatProfile: null,
        showSyntageInfo: false
    }),
    async mounted(){
        if(this.satProfile != null) {
            if(this.satProfile.credential_status == "error") {
                this.errorMssg = "No hemos podido validar tu cuenta del SAT, por favor verifica tus datos e intenta de nuevo.";
            }
            if(this.satProfile.credential_status == "invalid") {
                this.errorMssg = "Parece que la contraseña SAT (CIEC) es incorrecta, por favor verifica tus datos e intenta de nuevo.";
            }
        }
        this.isMounted = true;
    },
    computed: {
        comisionMercantilUrl(){
            return `${this.WebSiteUrl}/${this.applicantContractUrl}`;
        },
        passInputType() {
            return this.passInputPassMode ? "password" : textType;
        },
        passInputIcon() {
            return this.passInputPassMode ? iconEye : iconEyeOff;
        },
        business() {
            return this.guarantee.business
        },
        business_owner() {
            return this.business.personal;
        },
        business_address() {
            return this.business.address;
        },
        currentPhoneData() {
            return this.business_owner.phone;
        },
        currentPhoneNumber() {
            return this.currentPhoneData.phone
        },
        isDisable() {
            return this.password == null;       
        },
        satProfile() {
            return this.business.last_sat_profile;
        },
    },
    methods: {
        async saveDataForm() {
            this.errorMssg = null;
            if (!await this.validateForm()) {
                return;
            }

            // if(!this.legal) {
            //     this.missingFieldsNotif();
            //     return;
            // }
            
            const payload = {
                rfc: this.business.rfc,
                password: this.password,
                type: "ciec",
                auth_token: this.guarantee.auth_token
            }
            
            try {
                this.showLoading(true);
                const res = await this.publicApiPost(`/api/sat/credential`, payload);
                this.lastSatProfile = res.data;
                this.showLoading(false);
                this.$emit("updated", 1);
            } catch (error) {
                this.showLoading(false);
                if(error.response.data.error != null && error.response.data.error != undefined) {
                    this.errorMssg = `${error.response.data.error}, contáctanos a través del chat para darte una solución.`;
                } else {
                    this.errorMssg = "Tenemos problemas para procesar tu solicitud, intenta más tarde o contáctanos a través del chat."
                }
            }
        },
        async validateForm() {
            this.errorMssg = null;
            let r1 = await this.$validator.validateAll();
            return r1;
        },
        togglePassInputType() {
            this.passInputPassMode = !this.passInputPassMode;
        },
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
        let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },
    }
}
</script>

<style>

.fix-margin {
    margin: 1rem auto 0 auto !important;
}

.syntage {
    border: 2px solid #C8C8C8;
    margin: 2rem;
    border-radius: 5px;
    padding: 2rem;
    background-color: #c8c8c814;
}

.no-password {
    color: #13640C;
    font-weight: bold;
    font-style: italic;
    font-size: 12px;
    text-decoration-line: underline;
    cursor: pointer;
}
.content-center {
    place-content: center;
}

span.feather-icon .rfc {
    margin-top: -.5rem;
}
span.feather-icon .phone {
    margin-top: 0rem;
}
</style>