<template>
    <div id="os_pm_basic_info" class="'vx-col sm:w-5/6 md:w-2/3 lg:w-2/5 xl:w-2/5 xxl:w-2/5 m-0'">
        <div class="vx-row no-gutter items-center justify-center p-6">
            <vx-card class="login-card py-3 px-3">
                <div slot="no-body" class="">
                    <vs-progress :percent="15" :height="10" color="primary"></vs-progress>
                    <div class="vx-col sm:w-full">
                        <div class="login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full p-0" style="min-height: 200px">
                                    <!-- MAIN-DIV -->
                                    <div v-if="isMounted" class="px-6 pt-5 pb-5">
                                        <!-- HEADER -->
                                        <div class="vx-row mb-4 center">
                                            <div class="vx-col w-full">
                                                <p class="text-4xl card-sub-title">Información del negocio.</p>
                                            </div>
                                        </div>
                                        <!-- HEADER -->
                                        <!-- CONTENT -->
                                        <div class="main-form mt-base">
                                            <div class="vx-row">

                                                <div class="vx-col w-full mb-4">
                                                    <p class="mb-1">
                                                        <span class="bold black">RFC</span> <br>
                                                        {{ business.rfc }}
                                                    </p>
                                                    <small>En caso de que el RFC sea incorrecto nos puedes contactar a través del Chat para poder ayudarte a actualizarlo.</small>
                                                </div>
                                                
                                                <div :class="colClass">
                                                    <vs-input @input="(val) => (firstName = firstName.toUpperCase())"
                                                        class="w-full" label="Nombre de la empresa" name="firstName"
                                                        v-validate="requiredRules" v-model.trim="firstName"
                                                        :danger="hasError('firstName')"
                                                        :danger-text="errorText('firstName')"
                                                        :success="isSuccess('firstName')" icon-pack="feather"
                                                        :placeholder="requiredPlaceholder" />
                                                    <small><i>Nombre comercial de tu empresa.</i></small>
                                                </div>
                                                <div :class="colClass">
                                                    <!-- {{ collections }} -->
                                                    <label class="vs-input--label">Sector de la empresa</label>
                                                    <v-select name="business_activity" v-validate="requiredRules"
                                                        :danger="hasError('business_activity')"
                                                        :danger-text="errorText('business_activity')"
                                                        :success="isSuccess('business_activity')"
                                                        placeholder="Selecciona una opción o teclea alguna palabra"
                                                        v-model.lazy="business_activity" :clearable="false"
                                                        :searchable="true" :options="businessActivitiesFormat()"
                                                        :reduce="label => label.id" class="mr-2 w-full">
                                                    </v-select>
                                                    <small><i>Selecciona el giro de tu negocio.</i></small>
                                                </div>
                                                <!-- <div :class="colClass">
                                                <vs-input
                                                    @input="(val) => (rfc = rfc.toUpperCase())"
                                                    class="w-full"
                                                    label="RFC"
                                                    name="rfc"
                                                    v-validate="requiredRules"
                                                    v-model.trim="rfc"
                                                    :danger="hasError('rfc')"
                                                    :danger-text="errorText('rfc')"
                                                    :success="isSuccess('rfc')"
                                                    icon-pack="feather"
                                                    :placeholder="requiredPlaceholder"
                                                />
                                                <small><i>RFC de tu empresa.</i></small>
                                            </div>
                                            <div :class="colClass">
                                                <vs-input
                                                    @input="(val) => (rfc_confirmation = rfc_confirmation.toUpperCase())"
                                                    class="w-full"
                                                    label="Confirma el RFC"
                                                    name="rfc_confirmation"
                                                    v-validate="requiredRules"
                                                    v-model.trim="rfc_confirmation"
                                                    :danger="hasError('rfc_confirmation')"
                                                    :danger-text="errorText('rfc_confirmation')"
                                                    :success="isSuccess('rfc_confirmation')"
                                                    icon-pack="feather"
                                                    :placeholder="requiredPlaceholder"
                                                />
                                                <small><i>Confirma el RFC de tu empresa.</i></small>
                                            </div> -->
                                            </div>
                                            <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle"
                                                class="mb-5" color="danger">
                                                <span class="font-regular">{{ errorMssg }}</span>
                                            </vs-alert>
                                            <div class="justify-center vx-row">
                                                <!-- <div class="vx-col md:w-1/2 sm:w-full w-full">
                                                <vs-button class="w-full mt-4" type="border" color="dark" @click="isMainOnboardingStepVisible = false"
                                                    >Atrás</vs-button>
                                            </div> -->
                                                <div class="vx-col md:w-1/3 sm:w-full w-full">
                                                    <vs-button id="3_name_confirm_btn" class="w-full mt-4" color="dark"
                                                        @click="saveDataForm">Continuar</vs-button>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- END CONTENT DIV -->
                                    </div>
                                    <!-- END MAIN DIV -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import formatHelper from "@mixins/formatHelper";
import formHelper from '@components/mixins/formHelper';
export default {
    name: "GuaranteeOnboardingBusinessData",
    props: ["guarantee"],
    mixins: [inputHelper, formatHelper, formHelper],
    data: () => ({
        isMainOnboardingStepVisible: true,
        requiredRules: "required",
        errorMssg: null,
        colClass: "vx-col sm:w-full md:w-1/2 lg:w-1/2 mb-5",
        requiredPlaceholder: "(Requerido)",
        optionalPlaceholder: "(Opcional)",
        collections: {
            business_activities: null
        },
        isMounted: false,
        business_activity: null,
        rfc: null,
        rfc_confirmation: null,
        firstName: null
    }),
    computed: {
        business() {
            return this.guarantee.business;
        },
        business_owner() {
            return this.business.personal
        },
        business_main_user_personal() {
            return this.business.main_user_personal
        },
    },
    beforeMount() {
        this.setData();
    },
    async mounted() {
        this.showLoading(true);
        this.isMounted = false;
        await this.getCollections();
        this.isMounted = true;
        this.showLoading(false);
    },
    methods: {
        setData() {
            this.firstName = this.business.name;
            this.business_activity = this.business.business_activity_id;
        },
        async saveDataForm() {
            this.errorMssg = null;
            if (!(await this.validateForm())) {
                return;
            }

            this.showLoading(true, "Guardando información...");
            try {
                let payload = {
                    guarantee_id: this.guarantee.id,
                    business: {
                        business_activity: this.business_activity,
                        rfc: this.rfc,
                        name: this.firstName,
                        only_rfc: false
                    }
                };
                await this.publicApiPost(
                    `/api/guarantee/post/onboarding/business-data`,
                    payload
                );
                this.showLoading(false);
                this.$emit("updated", 1);
            } catch (e) {
                this.showLoading(false);
                this.showError(e);
            }
        },
        async getCollections() {
            try {
                let collectionsObjects = ['businessActivities'];

                let params = "with[]=" + collectionsObjects.join("&with[]=");
                let res = await this.publicApiGet(`/api/v1/forms/getFormCollections?${params}`);
                this.collections.business_activities = res.data.businessActivities;
            }
            catch (e) {
                this.warn(e);
                this.failed = true;
            }
        },
        async validateForm() {
            this.errorMssg = null;
            return this.$validator.validateAll();
        },
        showError(e) {
            this.warn(e);
            let err = "Ha ocurrido un error con la operación, intente más tarde";
            if (e.response && e.response.data && e.response.data.error) {
                err = e.response.data.error;
            }
            this.errorMssg = err;
        },
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
            let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },
        businessActivitiesFormat() {
            if (this.isMounted) {
                return this.collections.business_activities.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                });
            } else {
                return [];
            }
        }
    }
}
</script>