<template>
    <div id="os_pf_credit_history" class="vx-col sm:w-5/6 md:w-2/3 lg:w-2/3 xl:w-3/6 xxl:w-2/3 m-0">
        <div class="vx-row no-gutter items-center justify-center p-6">
            <!-- CHECK INFO -->
            <vx-card class="login-card py-3 px-3 mb-base">
                <div slot="no-body" class="">
                    <vs-progress :percent="65" :height="10" color="primary" class=""></vs-progress>
                    <!-- <vs-progress :percent="40" :height="10" color="primary"></vs-progress> -->
                    <div class="vx-col sm:w-full">
                        <div class="login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full p-0" style="min-height: 200px">
                                    <!-- MAIN-DIV -->
                                    <div class="p-10">
                                        <!-- HEADER -->
                                        <div class="vx-row mb-base">
                                            <div class="vx-col w-full">
                                                <p class="text-3xl card-sub-title">Confirma tu información.</p>
                                            </div>
                                        </div>
                                        <!-- CONTENT -->
                                        <div class="vx-row">
                                            <div class="vx-col sm:w-full md:w-1/2 lg:w-1/3 mb-4">
                                                <p>
                                                    <span>Nombre</span> <br>
                                                    <span class="bold">{{ personal.first_name }} {{ personal.second_name }} {{ personal.last_name_1 }} {{ personal.last_name_2 }}</span>
                                                </p>
                                            </div>
                                            <div class="vx-col sm:w-full md:w-1/2 lg:w-1/3 mb-4">
                                                <p>
                                                    <span>Lugar y fecha de nacimiento</span> <br>
                                                    <span class="bold">{{ personal.birth_state_named }} | {{ personal.birth_date }}</span> &nbsp;&nbsp;&nbsp;&nbsp; <span @click="editGeneralData = true;" class="clickable-img"><u><b>Editar</b></u></span>
                                                </p>
                                            </div>
                                            <div class="vx-col sm:w-full md:w-1/2 lg:w-1/3 mb-4">
                                                <p>
                                                    <span>Género</span> <br>
                                                    <span class="bold">{{ genderTranslate(personal.gender) }}</span>&nbsp;&nbsp;&nbsp;&nbsp; <span @click="editGeneralData = true;" class="clickable-img"><u><b>Editar</b></u></span>
                                                </p>
                                            </div>
                                            <div class="vx-col sm:w-full md:w-1/2 lg:w-1/3 mb-4">
                                                <p>
                                                    <span>RFC</span> <br>
                                                    <span class="bold">{{ personal.rfc }}</span>&nbsp;&nbsp;&nbsp;&nbsp; <span @click="editIdentifiersData = true;" class="clickable-img"><u><b>Editar</b></u></span>
                                                </p>
                                            </div>
                                            <div class="vx-col sm:w-full md:w-1/2 lg:w-1/3 mb-4">
                                                <p>
                                                    <span>CURP</span> <br>
                                                    <span class="bold">{{ personal.curp }}</span>&nbsp;&nbsp;&nbsp;&nbsp; <span @click="editIdentifiersData = true;" class="clickable-img"><u><b>Editar</b></u></span>
                                                </p>
                                            </div>
                                            <div class="vx-col sm:w-full md:w-1/2 lg:w-1/3 mb-4">
                                                <p>
                                                    <span>Teléfono</span> <br>
                                                    <span class="bold">{{ personal.country_calling_code }} {{ phoneFormat(personal.new_phone) }}</span>
                                                </p>
                                            </div>
                                            <div class="vx-col sm:w-full mb-4">
                                                <p>
                                                    <span>Dirección</span> <br>
                                                    <span class="bold">{{ personal.address.full_address }}</span>&nbsp; &nbsp; &nbsp; 
                                                    <span @click="editAddressData = true;" class="clickable-img"><u><b>Editar</b></u></span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
            <!-- AVISO -->
            <vx-card class="login-card py-3 px-3 mb-base">
                <div slot="no-body" class="">
                    <!-- <vs-progress :percent="40" :height="10" color="primary"></vs-progress> -->
                    <div class="vx-col sm:w-full">
                        <div class="login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full p-0" style="min-height: 200px">
                                    <!-- MAIN-DIV -->
                                    <div class="p-10">
                                        <!-- HEADER -->
                                        <div class="vx-row mb-base">
                                            <div class="vx-col w-full">
                                                <p class="text-3xl card-sub-title">Autorización de consulta crediticia.</p>
                                            </div>
                                        </div>
                                        <!-- CONTENT -->
                                        <div class="vx-row">
                                            <div class="vx-col sm:w-full md:w-11/12 mb-4 account-button mt-2">
                                                <div class="flex mb-2">
                                                    <vs-checkbox v-model="legal"
                                                        name="legal" v-validate="requiredRules"
                                                        :danger="hasError('legal')"
                                                        :danger-text="errorText('legal')"
                                                        :success="isSuccess('legal')"></vs-checkbox>
                                                    <p class="text-sm text-justify">
                                                        <span class="black">
                                                            Acepto el <a class="black bold" :href="$sanitizeUrl(privacyUrl)"
                                                                    target="_blank"><u>Aviso
                                                                    de
                                                                    Privacidad</u></a> y <a class="black bold" :href="$sanitizeUrl(termsUrl)"
                                                                    target="_blank"><u>Términos y
                                                                        Condiciones</u></a> y cláusulas de medios electrónicos tales como NIP
                                                        </span>
                                                    </p>
                                                </div>    
                                                <p class="black text-sm text-justify sm:ml-2 md:ml-10">
                                                    <br>
                                                    <span class="bold black">Autorización de Consulta.</span>
                                                    <br>
                                                    <br>
                                                    <br>

                                                    Autorizo expresamente a <b>Red Girasol S.A.P.l. de C.V. Institución de Financiamiento Colectivo</b>, para llevar a cabo Investigaciones sobre mi 
                                                    comportamiento crediticio en las Sociedades de Información Crediticia (SIC) que estime conveniente Conozco la naturaleza y alcance de la 
                                                    información que se solicitara, del uso que se le dora y que se podrán realizar consultas periódicas de mi historial crediticio. 
                                                    Consiento que esta autorización tenga una vigencia de 3 años contados a partir de hoy. y en su caso mientras mantengamos relación jurídica. 
                                                    Acepto que este documento quede bajo propiedad de <b>Red Girasol S.A.P.L. de C.V. Institución de Financiamiento Colectivo y/o SIC Consultada </b>
                                                    para efectos de control y cumplimiento del articulo 28 de la LRSIC
                                                </p>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
            <!-- NIP -->
            <vx-card class="login-card py-3 px-3">
                <div slot="no-body" class="">
                    <!-- <vs-progress :percent="40" :height="10" color="primary"></vs-progress> -->
                    <div class="vx-col sm:w-full">
                        <div class="login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full p-0" style="min-height: 200px">
                                    <!-- MAIN-DIV -->
                                    <div class="p-10">
                                        <!-- HEADER -->
                                        <div class="vx-row mb-base">
                                            <div class="vx-col w-full">
                                                <p class="text-3xl card-sub-title">Solicita tu NIP.</p>
                                            </div>
                                        </div>
                                        <!-- CONTENT -->
                                        <div class="vx-row">
                                            <div class="vx-col sm:w-full md:w-11/12 mb-base account-button mt-2">
                                                <p class="black text-sm text-justify">
                                                    Recibirás un código por mensaje SMS al teléfono que registraste. Si no lo recibes después de 1 minuto, puedes solicitar un código nuevo.
                                                    <br>
                                                    <br>
                                                    <span v-if="!nipIsCreated">
                                                        Asegúrate de que el teléfono sea correcto. Después de autorizar la consulta, no será posible modificarlo.
                                                    </span>
                                                    <span v-if="nipIsCreated && !isEditingPhone">
                                                        El código se ha enviado al número <b>{{personal.country_calling_code}} {{ phoneFormat(currentPhoneNumber) }}</b>
                                                    </span>
                                                    <span v-else-if="isEditingPhone">
                                                        <div class="flex mt-1">
                                                            <div class="flex">
                                                                <vue-country-code
                                                                    name="dialCode"
                                                                    class="vs-custom"
                                                                    data-vv-name="dialCode"
                                                                    :enableSearchField="true"
                                                                    searchPlaceholderText="Buscar código de país"
                                                                    :enabledCountryCode="true"
                                                                    @onSelect="onSelect"
                                                                    :preferredCountries="['MX']"
                                                                    :danger="hasError('dialCode')"
                                                                    :danger-text="errorText('dialCode')"
                                                                    :success="isSuccess('dialCode')">
                                                                </vue-country-code>
                                                                <vs-input
                                                                    class="w-full"
                                                                    label=""
                                                                    name="phone"
                                                                    type="tel"
                                                                    v-validate="'required|numeric'"
                                                                    v-model.lazy="currentPhone"
                                                                    :danger="hasError('phone')"
                                                                    :danger-text="errorText('phone')"
                                                                    :success="isSuccess('phone')"
                                                                    icon-pack="feather"
                                                                    placeholder="(Requerido)"
                                                                />
                                                            </div>
                                                            <feather-icon @click.stop="changeUserPhone()" icon="SaveIcon" svgClasses="w-5 h-5 hover:text-success stroke-current cursor-pointer phone" class="ml-2 pt-0" />
                                                        </div>
                                                    </span>
                                                        <br>
                                                        <br>
                                                    <span v-if="nipIsCreated && !showWhatsapp">
                                                        Puedes solicitar un nuevo código en <b>{{ whatsappTime }}</b> segundos
                                                    </span>

                                                    <div v-if="showWhatsapp" class="mb-2 mt-2">
                                                        <p class="bold mb-1 text-sm">
                                                            ¿No lo recibiste? Puedes intentarlo de nuevo cuando el código expire.
                                                        </p>
                                                        <div class="clickable-img" @click.stop="doRequestCDCNip(true)">
                                                            <vs-chip class="mr-2 bold">Enviar Whatsapp a {{ personal.country_calling_code }}{{
                                                    anonymousFormatPhone(personal.new_phone) }}</vs-chip>
                                                        </div>
                                                        <div class="clickable-img" @click.stop="doRequestCDCNip()">
                                                            <vs-chip class="mr-2 bold">Enviar SMS a {{personal.country_calling_code }}{{
                                                    anonymousFormatPhone(currentPhoneNumber) }}</vs-chip>
                                                        </div>
                                                    </div>

                                                </p>
                                            </div> 
                                        </div>
                                        
                                        <vs-alert
                                            v-if="errorMssg"
                                            icon-pack="feather"
                                            icon="icon-alert-triangle"
                                            class="mb-5"
                                            color="danger"
                                        >
                                            <span class="font-regular">{{ errorMssg }}</span>
                                        </vs-alert>
                                        <div class="justify-left vx-row">
                                            <!-- <div class="vx-col md:w-1/2 sm:w-full w-full">
                                                <vs-button class="w-full mt-4" type="border" color="dark" @click="isMainOnboardingStepVisible = false"
                                                    >Atrás</vs-button>
                                            </div> -->
                                            <div v-if="nipIsCreated" class="vx-col sm:w-full md:w-1/5 sm:w-full w-full">
                                                <vx-input-group class="w-full">
                                                    <vs-input 
                                                        v-model.lazy="nipRequested"
                                                        v-validate="requiredRules" 
                                                        :danger="hasError('nip')"
                                                        :danger-text="errorText('nip')"
                                                        type="text"
                                                        name="nip" />

                                                </vx-input-group>
                                            </div>
                                            <div class="vx-col sm:w-full md:w-1/4">
                                                <vs-button v-if="!nipIsCreated" @click.stop="doRequestCDCNip()" :disabled="nipIsCreated" id="4_stakeholders_confirm_btn" class="w-full" color="dark">Solicitar</vs-button>
                                                <vs-button v-else @click.stop="saveDataForm()" :disabled="isDisable" id="4_stakeholders_confirm_btn" class="w-full" color="dark">Autorizar</vs-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>

        <vs-popup
            :active.sync="editGeneralData"
            title="Edita tu información">
            <div class="vx-row">
                <div class="vx-col w-full">
                    <GuaranteeOnboardingGeneralData :guarantee="guarantee" @updated="updateForms" :isEditing="editGeneralData"/>
                </div>
            </div>
        </vs-popup>
        <vs-popup
            :active.sync="editIdentifiersData"
            title="Edita tu información">
            <div class="vx-row">
                <div class="vx-col w-full">
                    <GuaranteeOnboardingIdentifiers :guarantee="guarantee" @updated="updateForms" :isEditing="editIdentifiersData"/>
                </div>
            </div>
        </vs-popup>
        <vs-popup
            :active.sync="editAddressData"
            id="editAddressData"
            title="Edita tu información">
            <div class="vx-row">
                <div class="vx-col w-full">
                    <GuaranteeOnboardingAddress :guarantee="guarantee" @updated="updateForms" :isEditing="editAddressData"/>
                </div>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import formatHelper from "@mixins/formatHelper";
import { ScoreService } from "@/services/score/ScoreService.js";
import GuaranteeOnboardingGeneralData from "./GuaranteeOnboardingGeneralData.vue";
import GuaranteeOnboardingIdentifiers from "./GuaranteeOnboardingIdentifiers.vue";
import GuaranteeOnboardingAddress from "./GuaranteeOnboardingAddress.vue";

export default {
    name: "GuaranteeOnboardingCDCRequest",
    props: ["guarantee"],
    mixins: [formatHelper],
    components: {
        GuaranteeOnboardingGeneralData,
        GuaranteeOnboardingIdentifiers,
        GuaranteeOnboardingAddress
    },
    data() {
        return {
            requiredRules: 'required',
            colClass: "vx-col sm:w-full md:w-2/3 lg:w-2/3 mb-5 mt-2",
            passInputPassMode: true,
            legal: false,
            errorMssg: null,
            isMounted: false,
            syntageLogo: "https://cdn.redgirasol.com/applicants/syntage_schema.png",
            password: null,
            requiredPlaceholder: "(Requerido)",
            isEditingRFC: false,
            isEditingPhone: false,
            country_calling_code: null,
            currentPhone: null,
            currentRfc: null,
            nipIsCreated: false,
            nipRequested: null,
            applicantContractType: 4,
            applicantContractUrl: 'contrato-de-comision-mercantil-cliente',
            advertisingChecked: true,
            showWhatsapp: false,
            whatsappTime: 30,
            nipIsCreated: false,
            satProfile: null,
            editGeneralData: false,
            editIdentifiersData: false,
            editAddressData: false,
            guaranteeBlog: "https://soporte.redgirasol.com/support/solutions/articles/48001256956--qu%C3%A9-es-un-obligado-solidario-",
            privacyUrl: "https://www.redgirasol.com/aviso-de-privacidad",
            termsUrl: "https://www.redgirasol.com/terminos-y-condiciones",
        }
    },
    async mounted() {
    },
    computed: {
        personal() {
            return this.guarantee.personal;
        },
        currentPhoneNumber() {
            return this.personal.new_phone;
        },
        isDisable() {
            return this.isEditingPhone || !this.legal || this.nipRequested == null
        },
    },
    methods: {
        updateForms(e) {
            this.$emit('updated', 1);
            this[e] = false;
        },
        async saveDataForm() {
            this.errorMssg = null;

            if(!this.legal) {
                this.missingFieldsNotif();
                return;
            }
            
            const payload = {
                personal_profile_id: this.guarantee.personal_profile_id,
                verification_code: this.nipRequested,
                phone_id: this.guarantee.personal.phone_id,
                guarantee_id: this.guarantee.id,
            };
            
            try {
                this.showLoading(true);
                const res = await this.publicApiPost(`/api/guarantee/post/cdc-request`, payload);
                if (res.data != "success") {
                    this.nipIsCreated = false;
                    let error = res;
                    this.missingFieldsNotif(null, error);
                    this.showLoading(false);
                } else {
                    this.hasRequested = true;
                    this.saveSuccessNotif();
                    this.showLoading(false);
                    this.$emit('updated', 1);
                }
                this.showLoading(false);
                this.$emit("updated", 1);
            } catch (error) {
                this.showLoading(false);
                this.errorMssg = "No hemos podido realizar la operación, por favor ponte en contacto con nosotros a través del Chat para revisar tu caso."
                console.log(error);
            }
        },
        async doRequestCDCNip(isWhatsapp = true)
        {
            try {
                this.showLoading(true, "Enviando código de verificación...")
                const res = await this.publicApiPost(`/api/guarantee/post/verification-code/${this.guarantee.personal.phone_id}?whatsapp=${isWhatsapp}`);
                this.nipIsCreated = true;
                if (!this.showWhatsapp) {
                    this.initNipCounter(30);
                }
                this.nipCounter++;
                this.showLoading(false);
                this.saveSuccessNotif(null, `Te hemos enviado un código por mensaje de texto al siguiente número: ${this.personal.country_calling_code} ${this.anonymousFormatPhone(this.personal.new_phone)}`);
            } catch (error) {
                console.log(error);
            }
        },
        initNipCounter() {
        let timer = setInterval(() => {
                --this.whatsappTime;
                if (this.whatsappTime == 0) {
                    this.showWhatsapp = true;
                    clearInterval(timer);
                }
            }, 1000);
        },
        async requestPF() {
            this.showLoading(true);
            try {
                const response = await ScoreService.publicRequestPF({
                persona: {
                    apellidoPaterno: this.personal.last_name_1,
                    apellidoMaterno: this.personal.last_name_2,
                    primerNombre: this.personal.first_name,
                    segundoNombre: this.personal.second_name,
                    fechaNacimiento: this.personal.birth_date,
                    rfc: this.personal.rfc,
                    nacionalidad: "MX"
                },
                domicilio: {
                    direccion: this.personal.address.street,
                    coloniaPoblacion: this.personal.address.neighborhood_model.name,
                    delegacionMunicipio: this.personal.address.neighborhood_model.city.name,
                    ciudad: this.personal.address.neighborhood_model.city.name,
                    estado: this.personal.address.neighborhood_model.city.state.cc_code,
                    cp: this.personal.address.neighborhood_model.zipcode
                }
                });
                this.showLoading(false);
                if (!response.error) {
                this.$emit("updated", 1);
                this.saveSuccessNotif();
                }
            } catch (error) {
                this.showLoading(false);
                this.failedOperationNotif()
                console.log(error);
            }
            this.showLoading(false);
        },
        editPhone() {
            this.currentPhone = this.currentPhoneNumber;
            this.isEditingPhone = true;
        },
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
        let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },
    }
}
</script>

<style>

#editAddressData .vs-popup {
    width: 50% !important;
}

</style>