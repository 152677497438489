<template>
    <div id="os_pm_rep_legal-onboarding-general" class="vx-col sm:w-5/6 md:w-2/3 lg:w-2/5 xl:w-2/5 xxl:w-2/5 m-0">
        <div class="vx-row no-gutter items-center justify-center p-6">
            <vx-card class="login-card py-3 px-3">
                <vs-progress :percent="75" :height="10" color="primary" class="mb-2"></vs-progress>
                <div class="vx-row w-full">
                    <div class="vx-col w-full mb-base">
                        <p class="text-4xl card-sub-title">Representante legal.</p>
                    </div>
                    <div class="vx-col sm:w-full" style="min-height: 200px">
                        <!-- MAIN-DIV -->
                        <div v-if="isMounted" class="main-form mt-2">
                            <!-- CONTENT -->

                            <div class="vx-row">
                                <div class="vx-col sm:w-full md:w-1/2">
                                    <p class="mb-4 ml-1 text-lg">
                                        <span class="bold">Email</span> <br>
                                        {{ guarantee.email }}
                                    </p>
                                </div>
                                <div class="vx-col sm:w-full md:w-1/2">
                                    <p class="mb-4 ml-1 text-lg">
                                        <span class="bold">Teléfono</span> <br>
                                        ({{ business_owner.country_calling_code }}) {{ phoneFormat(business_owner.new_phone) }}
                                    </p>
                                </div>
                                <div class="vx-col sm:w-full  mb-4">
                                    <p class="text-sm">
                                        Si alguno de estos datos no es correcto por favor contacta a {{ loan.applicant.name }} para que se ponga en comunicación con nosotros vía chat y podamos revisar el caso.
                                    </p>
                                </div>
                            </div>

                            <div class="vx-row">
                                <div :class="colClass">
                                    <vs-input @input="(val) => (firstName = firstName.toUpperCase())" class="w-full"
                                        label="Primer nombre" name="firstName" v-validate="requiredRules"
                                        v-model.trim="firstName" :danger="hasError('firstName')"
                                        :danger-text="errorText('firstName')" :success="isSuccess('firstName')"
                                        icon-pack="feather" :placeholder="requiredPlaceholder" />
                                </div>
                                <div :class="colClass">
                                    <vs-input @input="(val) => (secondName = secondName.toUpperCase())" class="w-full"
                                        label="Segundo nombre" name="secondName" v-model.trim="secondName"
                                        :danger="hasError('secondName')" :danger-text="errorText('secondName')"
                                        :success="isSuccess('secondName')" icon-pack="feather"
                                        :placeholder="optionalPlaceholder" />
                                </div>
                                <div :class="colClass">
                                    <vs-input @input="(val) => (lastName1 = lastName1.toUpperCase())" class="w-full"
                                        label="Primer apellido" name="lastName1" v-validate="requiredRules"
                                        v-model.trim="lastName1" :danger="hasError('lastName1')"
                                        :danger-text="errorText('lastName1')" :success="isSuccess('lastName1')"
                                        icon-pack="feather" :placeholder="requiredPlaceholder" />
                                </div>
                                <div :class="colClass">
                                    <vs-input @input="(val) => (lastName2 = lastName2.toUpperCase())" class="w-full"
                                        label="Segundo apellido" name="lastName2" v-model.trim="lastName2"
                                        :danger="hasError('lastName2')" :danger-text="errorText('lastName2')"
                                        :success="isSuccess('lastName2')" icon-pack="feather"
                                        :placeholder="optionalPlaceholder" />
                                </div>
                                <div :class="colClass">
                                    <vs-select label="Género" name="gender" v-validate="requiredRules"
                                        :danger="hasError('gender')" :danger-text="errorText('gender')"
                                        :success="isSuccess('gender')" placeholder="Selecciona una opción."
                                        v-model.lazy="gender" class="mr-2 w-full">
                                        <vs-select-item v-for="(item, index) in genderOptions" :text="item.label"
                                            :key="index" :value="item.value"></vs-select-item>
                                    </vs-select>
                                    <small><i>El género que está en tu acta de nacimiento.</i></small>
                                </div>
                                <div :class="colClass">
                                    <label class="vs-input--label">Fecha de nacimiento</label>
                                    <vs-input class="w-full" type="date" v-model.lazy="birthDate"
                                        v-validate="requiredRules" name="birthDate" :danger="hasError('birthDate')"
                                        :danger-text="errorText('birthDate')" :success="isSuccess('birthDate')"
                                        format="dd/MM/yyyy" :max="disabledDates.from" />
                                    <!-- <datepicker
                                class="w-full"
                                v-model.lazy="birthDate"
                                name="birthDate"
                                :language="lang_es"
                                v-validate="requiredRules"
                                :danger="hasError('birthDate')"
                                :danger-text="errorText('birthDate')"
                                :success="isSuccess('birthDate')"
                                format="dd/MM/yyyy"
                                :disabled-dates="disabledDates"
                                :placeholder="requiredPlaceholder"
                                :use-utc="true"
                                :typeable="true"
                            /> -->
                                </div>
                                <div :class="colClass">
                                    <label class="vs-input--label">Estado de nacimiento</label>
                                    <v-select class="vs-custom" name="birthState" :class="{
                            'has-error': hasError('birthState'),
                            'is-success': isSuccess('birthState'),
                        }" v-model.lazy="birthState" v-validate="requiredRules" :options="collections.states"
                                        :reduce="(item) => item.value" :clearable="false"
                                        placeholder="Selecciona una opción" :searchable="false">
                                    </v-select>
                                    <v-error v-if="hasError('birthState')" :error="errorText('birthState')" />
                                </div>
                                <div v-if="isForeign" :class="colClass">
                                    <label class="vs-input--label">País de nacimiento</label>
                                    <v-select class="vs-custom" name="originCountry" :class="{
                            'has-error': hasError('originCountry'),
                            'is-success': isSuccess('originCountry'),
                        }" v-model.lazy="originCountry" v-validate="requiredRules" :options="collections.countries"
                                        :reduce="(item) => item.value" :clearable="false"
                                        placeholder="Selecciona una opción" :searchable="false">
                                    </v-select>
                                    <v-error v-if="hasError('originCountry')" :error="errorText('originCountry')" />
                                </div>
                                <div v-if="isForeign" :class="colClass">
                                    <label class="vs-input--label">Nacionalidad</label>
                                    <v-select class="vs-custom" name="citizenship" :class="{
                            'has-error': hasError('citizenship'),
                            'is-success': isSuccess('citizenship'),
                        }" v-model.lazy="citizenship" v-validate="requiredRules" :options="collections.countries"
                                        :reduce="(item) => item.value" :clearable="false"
                                        placeholder="Selecciona una opción" :searchable="false">
                                    </v-select>
                                    <v-error v-if="hasError('citizenship')" :error="errorText('citizenship')" />
                                </div>
                            </div>

                            <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle" class="mb-5"
                                color="danger">
                                <span class="font-regular">{{ errorMssg }}</span>
                            </vs-alert>
                            <!-- END CONTENT DIV -->
                        </div>
                        <!-- END MAIN DIV -->
                    </div>
                </div>
                <div class="justify-end vx-row">
                    <!-- <div class="vx-col md:w-1/2 sm:w-full w-full">
                <vs-button class="w-full mt-4" type="border" color="dark" @click="isMainOnboardingStepVisible = false"
                    >Atrás</vs-button>
            </div> -->
                    <div class="vx-col w-full sm:w-full md:w-1/4">
                        <vs-button id="3_name_confirm_btn" class="w-full mt-4" color="dark"
                            @click="saveDataForm">Continuar</vs-button>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import dateHelper from "@mixins/dateHelper";
import { es } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import formatHelper from "@mixins/formatHelper.js";
const genderOptions = [
    { label: "Mujer", value: 2 },
    { label: "Hombre", value: 1 },
    // { label: "No definido", value: 0 },
    // { label: "No binario", value: 0 },
]
export default {
    name: "ApplicantOnboardingGeneralMainUser",
    props: ["guarantee"],
    mixins: [inputHelper, dateHelper, formatHelper],
    components: {
        Datepicker
    },
    data: () => ({
        isMainOnboardingStepVisible: true,
        requiredRules: "required",
        errorMssg: null,
        colClass: "vx-col sm:w-full md:w-1/2 lg:w-1/2 mb-5",
        colClassFull: "vx-col w-full mb-5",
        requiredPlaceholder: "(Requerido)",
        optionalPlaceholder: "(Opcional)",
        firstName: null,
        secondName: null,
        lastName1: null,
        lastName2: null,
        gender: null,
        genderOptions: genderOptions,
        // INFORMACIÓN DE LA FECHA DE NACIMIENTO
        mexicoId: 700,
        lang_es: es,
        birthState: null,
        birthDate: null,
        originCountry: null,
        citizenship: null,
        birthStateIdForeign: null,
        isMounted: false,
        collections: {
            states: [],
            countries: [],
        },
        disabledDates: {
            from: new Date(),
        },
        showSyntageForm: true
    }),
    computed: {
        business() {
            return this.guarantee.business
        },
        business_owner() {
            return this.business.personal
        },
        isForeign() {
            return this.birthState == this.birthStateIdForeign;
        },
        loan() {
            return this.guarantee.loan;
        }
    },
    async mounted() {
        this.isMounted = false;
        await this.getCollections();
        this.setData();
        this.setDefaultDate();
        this.isMounted = true;
    },
    methods: {
        async saveDataForm() {
            this.errorMssg = null;
            if (!(await this.validateForm())) {
                return;
            }

            if (this.isAfter(this.birthDate, this.disabledDates.from)) {
                this.missingFieldsNotif(null, "La fecha de nacimiento no puede ser mayor a " + this.disabledDates.from);
                return;
            }

            this.showLoading(true, "Guardando información...");
            try {

                if (!this.isForeign) {
                    this.originCountry = this.mexicoId;
                    this.citizenship = this.mexicoId;
                }

                let payload = {
                    guarantee_id: this.guarantee.id,
                    first_name: this.firstName,
                    second_name: this.secondName,
                    last_name_1: this.lastName1,
                    last_name_2: this.lastName2,
                    gender: this.gender,
                    birth_state: this.birthState,
                    birth_date: this.birthDate,
                    origin_country: this.originCountry,
                    citizenship: this.citizenship
                };
                await this.publicApiPost(
                    `/api/guarantee/post/onboarding/business-personal-data`,
                    payload
                );
                this.showLoading(false);
                this.$emit("updated", 1);
            } catch (e) {
                this.showLoading(false);
                this.showError(e);
            }
        },
        async validateForm() {
            this.errorMssg = null;
            return this.$validator.validateAll();
        },
        async getCollections() {
            try {
                let collectionsObjects = ['countriesList', 'statesList'];
                let params = "with[]=" + collectionsObjects.join("&with[]=");
                let res = await this.publicApiGet(`/api/v1/forms/getPublicFormCollections?${params}`);
                let colls = res.data;
                colls.statesList.forEach(opt => {
                    if (opt.name == "EXTRANJERO (FOREIGN)") {
                        this.birthStateIdForeign = parseInt(opt.id);
                    }
                    this.collections.states.push({ value: parseInt(opt.id), label: opt.name }); //conversion a sintaxis de vx-select
                });
                colls.countriesList.forEach(opt => {
                    if (opt.id != 700 && opt.id != 600 && opt.id != 999) {
                        this.collections.countries.push({ value: parseInt(opt.id), label: opt.name }); //conversion a sintaxis de vx-select
                    }
                })
            }
            catch (e) {
            }
        },
        setDefaultDate() {
            const personal = this.business_owner;
            let minAge = this.formatDatepicker(new Date(this.minDateForValidAge(18)));
            let validDate = personal.birth_date != null ? this.formatDatepicker(new Date(personal.birth_date)) : minAge;
            this.disabledDates.from = minAge;
            // this.birthDate = validDate;
        },
        setData() {
            const personal = this.business_owner;
            this.firstName = personal.first_name;
            this.secondName = personal.second_name;
            this.lastName1 = personal.last_name_1;
            this.lastName2 = personal.last_name_2;
            this.gender = personal.gender;
            this.birthState = personal.birth_state;
            this.birthDate = personal.birth_date;
            this.originCountry = personal.origin_country;
            this.citizenship = personal.citizenship;
        },
        showError(e) {
            this.warn(e);
            let err = "Ha ocurrido un error con la operación, intente más tarde";
            if (e.response && e.response.data && e.response.data.error) {
                err = e.response.data.error;
            }
            this.errorMssg = err;
        },
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
            let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },
    }
}
</script>