<template>
    <div id="os_pf_basic_info" :class="isEditing ? 'vx-col w-full' : 'vx-col sm:w-5/6 md:w-2/3 lg:w-2/5 xl:w-2/5 xxl:w-2/5 m-0'">
        <div class="vx-row no-gutter items-center justify-center p-6">
            <vx-card class="login-card py-3 px-3">
                <div slot="no-body" class="">
                    <vs-progress v-if="!isEditing" :percent="90" :height="10" color="primary"></vs-progress>
                    <div class="vx-col sm:w-full">
                        <div class="login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full p-0" style="min-height: 200px">
                                    <!-- MAIN-DIV -->
                                    <div class="p-10">
                                        <!-- HEADER -->
                                        <div class="vx-row mb-4 center">
                                            <div class="vx-col w-full">
                                                <p class="text-4xl card-sub-title">Información personal.</p>
                                            </div>
                                        </div>
                                        <!-- HEADER -->
                                         <!-- MAIN CONTENT -->
                                        <div v-if="isMounted" class="main-form mt-base">
                                            <div class="vx-row">
                                                <div :class="colClass">
                                                    <vs-input
                                                        @input="(val) => (rfc = rfc.toUpperCase())"
                                                        class="w-full"
                                                        label="RFC"
                                                        name="rfc"
                                                        v-validate="requiredRules"
                                                        v-model.lazy="rfc"
                                                        :danger="hasError('rfc')"
                                                        :danger-text="errorText('rfc')"
                                                        :success="isSuccess('rfc')"
                                                        icon-pack="feather"
                                                        :placeholder="requiredPlaceholder"
                                                    />
                                                    <a
                                                        class="inline-link-primary"
                                                        target="_blank"
                                                        rel="noopener"
                                                        :href="$sanitizeUrl(rfcUrl)"
                                                        >Consultar mi RFC</a
                                                    >
                                                </div>
                                                <div :class="colClass">
                                                    <vs-input
                                                        @input="(val) => (curp = curp.toUpperCase())"
                                                        class="w-full"
                                                        label="CURP"
                                                        name="curp"
                                                        v-validate="requiredRules"
                                                        v-model.lazy="curp"
                                                        :danger="hasError('curp')"
                                                        :danger-text="errorText('curp')"
                                                        :success="isSuccess('curp')"
                                                        icon-pack="feather"
                                                        :placeholder="requiredPlaceholder"
                                                    />
                                                    <a
                                                        class="inline-link-primary"
                                                        target="_blank"
                                                        rel="noopener"
                                                        :href="$sanitizeUrl(curpUrl)"
                                                        >Consultar mi CURP</a
                                                    >
                                                </div>
                                            </div>
                                            <vs-alert
                                                v-if="errorMssg"
                                                icon-pack="feather"
                                                icon="icon-alert-triangle"
                                                class="mb-5"
                                                color="danger">
                                                <span class="font-regular">{{ errorMssg }}</span>
                                            </vs-alert>
                                            <div class="vx-row justify-center">
                                                <!-- <div class="vx-col md:w-1/2 sm:w-full w-full">
                                                    <vs-button id="6_rfc_curp_confirm_btn" class="w-full mt-4" color="dark" @click="false" type="border">Atrás</vs-button>
                                                </div> -->
                                                <div class="vx-col md:w-1/2 sm:w-full w-full">
                                                    <vs-button id="6_rfc_curp_confirm_btn" class="w-full mt-4" color="dark" @click="saveDataForm">Continuar</vs-button>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- MAIN CONTENT -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import formatHelper from "@mixins/formatHelper";
import inputHelper from "@mixins/inputHelper";
import formHelper from '@components/mixins/formHelper';
export default {
    name: "GuaranteeOnboardingIdentifiers",
    props: ["guarantee", "isEditing", "isRepLegal"],
    mixins: [formatHelper, inputHelper, formHelper],
    data() {
        return {
            isMounted: false,
            rfcUrl: "https://www.sat.gob.mx/aplicacion/operacion/31274/consulta-tu-clave-de-rfc-mediante-curp",
            curpUrl: "https://www.gob.mx/curp/",
            requiredRules: "required",
            errorMssg: null,
            colClass: "vx-col sm:w-full md:w-1/2 mb-5",
            requiredPlaceholder: "(Requerido)",
            optionalPlaceholder: "(Opcional)",
            rfc: null,
            curp: null,
        }
    },
    async mounted() {
        // this.showLoading(true);
        this.isMounted = false;
        this.setData();
        this.isMounted = true;
        // this.showLoading(false);
    },
    computed: {
        personal() {
            if(this.isRepLegal) {
                return this.guarantee.business.personal;
            }
            return this.guarantee.personal;
        },
        guaranteeId() {
            return this.guarantee.id;
        },
        loan() {
            return this.guarantee.loan
        },
    },
    methods: {
        async saveDataForm() {
            const event = this.isEditing ? "editIdentifiersData" : "saveIdentifiersData";
            this.errorMssg = null;
            if (!(await this.validateForm())) {
                return;
            }
            let validRfc = this.isValidRfcFormat(this.rfc);
            if(!validRfc){
                this.errorMssg = "La estructura de la clave RFC es incorrecta."
                return;
            }
            let validCurp = this.isValidCurpFormat(this.curp);
            if(!validCurp){
                this.errorMssg = "La estructura de la clave CURP es incorrecta."
                return;
            }
            
            let uniqueCurp = await this.validate_unique_curp(this.curp, this.personal.id);
            if(!uniqueCurp){
                this.sendIdentifiersNotification(
                    {
                        user_id: this.guaranteeId,
                        loan_id: this.loan.loan.id,
                        role: "guarantee",
                        flow: "CURP",
                        id: this.curp
                    }
                );
                this.errorMssg = `El CURP ${this.curp} ya ha sido registrado en el sistema. Ponte en contacto con nuestro equipo de soporte para más información.`
                return;
            }
            this.showLoading(true, "Guardando información...");
            try {
                let payload = {
                    guarantee_id: this.guaranteeId,
                    rfc: this.rfc,
                    curp: this.curp,
                };
                await this.publicApiPost(
                    "/api/guarantee/update/onboarding/personal-ids",
                    payload
                );
                this.showLoading(false);
                this.$emit("updated", event);
            } catch (e) {
                this.showLoading(false);
                this.showError(e);
            }
        },
        setData() {
            const personal = this.personal;
            this.rfc = personal.rfc;
            this.curp = personal.curp;
        },
        async validateForm() {
            this.errorMssg = null;
            return this.$validator.validateAll();
        },
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
            let ff = this.fields[val];
            return (ff && ff.required && ff.valid);
        },
    }
}
</script>

<style scoped>


</style>