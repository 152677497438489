<template>
    <div id="guarantee-onboarding-step-page" v-if="isMounted">
        <div>
            <div class="vx-row no-gutter items-center justify-center">
                <div class="vx-col w-full">
                    <div class="vx-row no-gutter items-center justify-center">
                        <GuaranteeOnbaordingPrivacyTerms v-if="currentOnboardingStep == null" :guarantee="base" @updated="getTicket"/>
                        <!-- PF -->
                        <GuaranteeOnboardingGeneralData v-else-if="currentOnboardingStep == 'os_pf_ap_tc_acceptance'" :guarantee="base" @updated="getTicket"/>
                        <GuaranteeOnboardingIdentifiers v-else-if="currentOnboardingStep == 'os_pf_basic_info'" :guarantee="base" @updated="getTicket"/>
                        <GuaranteeOnboardingAddress v-else-if="currentOnboardingStep == 'os_pf_rfc_curp'" :guarantee="base" @updated="getTicket"/>
                        <GuaranteeOnboardingCDCRequest v-else-if="currentOnboardingStep == 'os_pf_address'" :guarantee="base" @updated="getTicket"/>
                        <GuaranteeOnboardingIdentityVerification v-else-if="currentOnboardingStep == 'os_pf_credit_history'" :guarantee="base" @updated="getTicket"/>
                        <GuaranteeOnboardingEndPage v-else-if="currentOnboardingStep == 'os_pf_identity_verification'" :guarantee="base" @updated="getTicket"/>
                        <!-- PM -->
                        <GuaranteeOnboardingBusinessData v-else-if="currentOnboardingStep == 'os_pm_ap_tc_acceptance'" :guarantee="base" @updated="getTicket"/>
                        <GuaranteeOnboardingBusinessAddress v-else-if="currentOnboardingStep == 'os_pm_basic_info'" :guarantee="base" @updated="getTicket"/>
                        <GuaranteeOnboardingSyntagePage v-else-if="currentOnboardingStep == 'os_pm_address'" :guarantee="base" @updated="getTicket"/>
                        <GuaranteeOnboardingBusinessCDC v-else-if="currentOnboardingStep == 'os_pm_sat'" :guarantee="base" @updated="getTicket"/>
                        <GuaranteeOnboardingBusinessPersonal v-else-if="currentOnboardingStep == 'os_pm_credit_history'" :guarantee="base" @updated="getTicket"/>
                        <GuaranteeOnboardingIdentifiers v-else-if="currentOnboardingStep == 'os_pm_rep_legal'" :guarantee="base" @updated="getTicket" :isRepLegal="true"/>
                        <GuaranteeOnboardingIdentityVerification v-else-if="currentOnboardingStep == 'os_pm_rep_legal_curp_rfc'" :guarantee="base" @updated="getTicket" :isRepLegal="true"/>
                        <GuaranteeOnboardingEndPage v-else-if="currentOnboardingStep == 'os_pm_rep_legal_identity_verification'" :guarantee="base" @updated="getTicket"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GuaranteeOnbaordingPrivacyTerms from "./components/GuaranteeOnboardingPrivacyTerms.vue";
import GuaranteeOnboardingGeneralData from "./components/GuaranteeOnboardingGeneralData.vue";
import GuaranteeOnboardingIdentifiers from "./components/GuaranteeOnboardingIdentifiers.vue";
import GuaranteeOnboardingAddress from "./components/GuaranteeOnboardingAddress.vue";
import GuaranteeOnboardingCDCRequest from "./components/GuaranteeOnboardingCDCRequest.vue";
import GuaranteeOnboardingIdentityVerification from "./components/GuaranteeOnboardingIdentityVerification.vue";
import GuaranteeOnboardingEndPage from "./components/GuaranteeOnboardingEndPage.vue";
import GuaranteeOnboardingBusinessData from "./components/business/GuaranteeOnboardingBusinessData.vue";
import GuaranteeOnboardingBusinessAddress from "./components/business/GuaranteeOnboardingBusinessAddress.vue";
import GuaranteeOnboardingSyntagePage from "./components/business/GuaranteeOnboardingSyntagePage.vue";
import GuaranteeOnboardingBusinessCDC from "./components/business/GuaranteeOnboardingBusinessCDC.vue";
import GuaranteeOnboardingBusinessPersonal from "./components/business/personal/GuaranteeOnboardingBusinessPersonal.vue";

const requiredObjects = [
    'personal',
    'personal.identityVerifications',
    'personal.phone',
    'personal.address',
    'business',
    'business.address',
    'business.phone',
    'business.personal',
    'business.personal.identityVerifications',
    'business.personal.phone',
    'business.personal.address',
];

export default {
    name: "GuaranteeOnboardingStepPage",
    //   mixins: [inputHelper, userIdentityHelper],
    components: {
        GuaranteeOnbaordingPrivacyTerms,
        GuaranteeOnboardingGeneralData,
        GuaranteeOnboardingIdentifiers,
        GuaranteeOnboardingAddress,
        GuaranteeOnboardingCDCRequest,
        GuaranteeOnboardingIdentityVerification,
        GuaranteeOnboardingEndPage,
        GuaranteeOnboardingBusinessData,
        GuaranteeOnboardingBusinessAddress,
        GuaranteeOnboardingSyntagePage,
        GuaranteeOnboardingBusinessCDC,
        GuaranteeOnboardingBusinessPersonal
    },
    data() {
        return {
            isMounted: false,
            colClassFull: "vx-col w-full mb-5",
            base: null,
        };
    },
    computed: {
        currentOnboardingStep() {
            return this.base.guarantee_profile_step;
        },
        isMoral() {
            return this.base.business_profile_id != null;
        }
    },
    async mounted() {
        this.isMounted = false;
        await this.getTicket();
        // await this.getGuaranteeData();
        this.isMounted = true;
    },
    methods: {
        async getTicket() {
            try {
                if(this.base != null) {
                    await this.checkGuaranteeOnboardingStep();
                }
                this.showLoading(true);
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                this.ticket = urlParams.get('t');
                let params = "with[]=" + requiredObjects.join("&with[]=");
                const res = await this.publicApiGet(`/api/guarantee/get/info/${this.ticket}?${params}`);

                if (res.status != 200) {
                    this.showLoading(false);
                    this.isError = true;
                    this.missingFieldsNotif(null, "Hemos tenido problemas para continuar con tu proceso de verificación de identidad, por favor ponte en contacto con nuestro equipo de soporte a través del chat.")
                    return;
                }
                this.showLoading(false);
                this.base = res.data;
            } catch (error) {

            }
        },
        async checkGuaranteeOnboardingStep() {
            try {
                this.showLoading(true);
                await this.publicApiPost(`/api/guarantee/update/onboarding/${this.base.id}/check-step`);
                this.showLoading(false);
            } catch (error) {
                console.log(error);
            }
        }
    }
}
</script>