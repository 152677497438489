<template>
    <div id="syntage">
        <div class="vx-row align-center">
            <div class="vx-col w-full mb-base">
                <h2 class="">
                    ¿Por qué pedimos la cuenta del STA (CIEC)?
                </h2>
            </div>
            <div class="vx-col w-full">
                <vs-tabs alignment="center">
                    <vs-tab label="Rapidez">
                        <div class="mt-base">
                            <p class="black mb-base">Asociar tu cuenta del SAT hace que la solicitud sea más <span class="bold">rápida</span> y <span class="bold">segura.</span></p>
                            <ul class="align-left ml-12 mt-4 mb-base black ul-disc">
                                <li class="mb-5">Obtienes mejores condiciones de plazos y tasas.</li>
                                <li class="mb-5">Podemos ofrecerte soluciones personalizadas.</li>
                                <li class="mb-5">Agilizas el proceso de aprobación de crédito.</li>
                            </ul>
                        </div>
                    </vs-tab>
                    <vs-tab label="Seguridad">
                        <div class="mt-base">
                            <p class="black mb-base">Asociar tu cuenta del SAT hace que la solicitud sea más <span class="bold">rápida</span> y <span class="bold">segura.</span></p>
                            <ul class="align-left ml-12 mt-4 mb-base black ul-disc">
                                <li class="mb-5">RedGirasol no puede modificar tu información de facturación, solo puede consultar tu información histórica.</li>
                                <li class="mb-5">Tu información permanecerá siempre confidencial y solo será usada para mejorar tu experiencia de usuario..</li>
                            </ul>
                        </div>
                    </vs-tab>
                </vs-tabs>
            </div>
            <div class="vx-col w-full mb-base">
                <p class="bold black clickable-img">
                    <a class="black" :href="$sanitizeUrl(avisoPrivacidadUrl)" target="_blank" rel="noopener noreferrer">Consulta nuestro aviso de privacidad.</a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        drawer: true,
        drawerRight: true,
        right: null,
        left: null
    }),
    computed: {
        avisoPrivacidadUrl(){
            return `https://www.redgirasol.com/aviso-de-privacidad`;
        },
    },
    props: {
        source: String
    }
}
</script>

<style>

#syntage .vs-tabs--li span {
    font-size: x-large;
    font-weight: bold;
    color: #2d2d2d;
    margin-left: .5rem;
}

</style>