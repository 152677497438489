<template>
    <div id="guarantee-onboarding-start-page" class="vx-col sm:w-5/6 md:w-1/3 lg:w-1/3 xl:w-2/6 xxl:w-1/3 m-0">
        <div class="vx-row no-gutter items-center justify-center p-6">
            <div class="login-card py-3 px-3">
                <div class="vx-row center">
                    <div class="vx-col w-full banner p-24 shadow-2">
                        <p class="title-extra extra-bold">¡Listo!</p>
                    </div>
                    <div class="black p-12 vx-col w-full card-text text-lg shadow-2">
                        <p>
                            Gracias por la confianza. Haz sido registrado como obligado solidario del crédito de {{ applicantName }}. 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "GuaranteeOnboardingEndPage",
    props: ["guarantee"],
    data() {
        return {

        }
    },
    async mounted() {
    },
    computed: {
        identityCardURL() {
            return `${this.StorageURL}/applicants/guarantees-onboarding/banner-sunflower.png`;
        },
        applicantName() {
            return this.guarantee.loan.applicant.name;
        }
    },
    methods: {

    }
}
</script>

<style scoped>

.center {
    text-align: center;
}

.banner {
    background-image: url("https://cdn.redgirasol.com/applicants/guarantees-onboarding/banner-sunflower.png");
    background-size: cover;
    border-radius: 10px 10px 0 0;
}

.title-extra {
    color: #f5f5f5;
    font-weight: bold;
    font-size: 4rem;
    font-family: 'gilroybold';
}

.card-text {
    background-color: #f5f5f5;
    border-radius: 0 0 10px 10px;
    font-family: 'gilroyregular';
}


</style>